<template>
  <div id="mobileRedirect"><i class="el-icon-loading"></i> Loading...</div>
</template>

<script>
import userApi from '../../api/user'
import {mapActions} from 'vuex'

export default {
    name: 'mobileRedirect',
    data() {
        return {
            path: ''
        }
    },
    mounted(){
        this.path = decodeURI(this.$route.query.goto)
        var action = this.$route.query.action
        var htmlHader = this.$route.query.htmlHeader
        if(htmlHader === undefined || htmlHader.length === 0) {
            htmlHader = 0
        }  else {
            htmlHader = parseInt(htmlHader)
        }
        var token = decodeURI(this.$route.query.token)
        console.log(action, token, this.path)
        if(action === 'login' && token.length > 0) {
            userApi.loginWithMToken(token).then(resp=>{
                this.appLogin(resp)
                window.location.href = this.path
            })
        } else {
            window.length.href = this.path
        }
        this.setFrom('app')
        this.setHtmlHeader(htmlHader)
    },
    methods: {
        ...mapActions('client', ['appLogin', 'setFrom', 'setHtmlHeader'])
    }
}
</script>

<style scoped>
#mobileRedirect{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -60px;
    font-size: 24px;
}
</style>>